import { Component } from 'react';
import Header from '../components/Header';
import Hero from '../components/Hero';
import LandingMainSection from '../components/LandingMainSection';
import Footer from '../components/Footer';

class LandingPage extends Component {
    render(){
        return (
            <>
                <Header />
                <Hero />
                <LandingMainSection />
                <Footer />
            </>
        );
    }
}

export default LandingPage;