import logo from "../Byte_the_Code_logo.jpeg";
export default function Header(){
    return(
        <header className="main-header">
            <div className="wrapper">
                <div className="header-wrapper">
                    <div className="header-content">
                        <div>
                            <p className="header-logo" style={{display: "none",color: "orange", textShadow: "1px 2px 3px rgba(0,0,0,0.4)", fontSize: 17, fontWeight: "bolder"}}>
                                Byte the Code LLC
                            </p>
                            <p className="header-logo">
                                <img 
                                    style={{width: 100, height: "auto", border: "4px solid red"}}
                                    src={logo} alt="site logo" />
                            </p>
                        </div>
                        <div>
                            <p className="header-contact-social-icons" style={{color: 'red', textAlign: "center"}}>
                                <span style={{cursor: "pointer", margin: "0 7px"}}><i style={{fontSize: 17}} className="fa-solid fa-envelope"></i></span>
                                <span style={{cursor: "pointer", margin: "0 7px"}}><i style={{fontSize: 17}} className="fa-solid fa-phone"></i></span>
                                <span style={{cursor: "pointer", margin: "0 7px"}}><i style={{fontSize: 17}} className="fa-brands fa-linkedin"></i></span>
                                <span style={{cursor: "pointer", margin: "0 7px"}}><i style={{fontSize: 17}} className="fa-brands fa-twitter"></i></span>
                                <span style={{cursor: "pointer", margin: "0 7px"}}><i style={{fontSize: 17}} className="fa-brands fa-facebook"></i></span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}