import WelldugoLogo from "../WelldugoLogo.png";

export default function LandingMainSection(){
    return(
        <main className="landing-main-section">
            <div className="landing-page-main-icons-section" style={{paddingTop: 10}}>
                <div className="wrapper">
                    <div className="content our-promises-section" style={{paddingTop: 70}}>
                        <p className="hp-page-section-title" style={{fontWeight: "bolder", /*color: "rgb(0, 60, 109)",*/ color: "rgba(255,255,255,0.5)", textAlign: "center", fontSize: 27, marginBottom: 30}}>
                            - Our Promises -
                        </p>
                        <div className="body-section-with-icons">
                            <div className="body-section-with-icons-item">
                                <p style={{textAlign: "center", /*color: "rgba(0,0,0,0.5)",*/ color: "skyblue", margin: 0}}>
                                    <i style={{fontSize: 60}} className="fa-solid fa-business-time"></i></p>
                                <p style={{margin: 0, marginTop: 10, textAlign: "center", fontSize: 19, fontWeight: "bolder", /*color: "rgb(0, 60, 109)"*/ color: "red"}}>
                                    QUICK ACTION</p>
                                <p style={{textAlign: "center", color: "skyblue"}}>
                                    Immediately deploying resources towards research and development upon discovery of the field of interest</p>
                            </div>
                            <div className="body-section-with-icons-item">
                                <p style={{textAlign: "center", /*color: "rgba(0,0,0,0.5)",*/ color: "skyblue", margin: 0}}>
                                    <i style={{fontSize: 60}} className="fa-solid fa-chart-simple"></i></p>
                                <p style={{margin: 0, marginTop: 10, textAlign: "center", fontSize: 19, fontWeight: "bolder", /*color: "rgb(0, 60, 109)"*/ color: "red"}}>
                                    LEADING INOVATION</p>
                                <p style={{textAlign: "center", color: "skyblue"}}>
                                    Taking initiatives towards revolutionizing various industries</p>
                            </div>
                            <div className="body-section-with-icons-item">
                                <p style={{textAlign: "center", /*color: "rgba(0,0,0,0.5)",*/ color: "skyblue", margin: 0}}>
                                    <i style={{fontSize: 60}} className="fa-solid fa-handshake"></i></p>
                                <p style={{margin: 0, marginTop: 10, textAlign: "center", fontSize: 19, fontWeight: "bolder", /*color: "rgb(0, 60, 109)"*/ color: "red"}}>
                                    SAAS MEET INDUSTRY</p>
                                <p style={{textAlign: "center", color: "skyblue"}}>
                                    Providing cutting-edge SaaS and AI solutions in various industries</p>
                            </div>
                        </div>
                        <div className="hero-form-section">
                            <div className="hero-form-section-content-container" style={{padding: 15, paddingTop: 50}}>
                                <p style={{textAlign: "center", color: "skyblue", textShadow: "0 0 10px rgba(0,0,0,0.4)", fontSize: 24, fontWeight: "bolder"}}>
                                    Join Our Team</p>
                                <div className="form-inputs">
                                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                                        <div style={{width: "calc(50% - 3px)"}} className="contained-input-fld">
                                            <i className="fa-solid fa-user"></i>
                                            <input type="text" placeholder="First Name"/>
                                        </div>
                                        <div style={{width: "calc(50% - 3px)"}} className="contained-input-fld">
                                            <i className="fa-solid fa-user"></i>
                                            <input type="text" placeholder="Last Name"/>
                                        </div>
                                    </div>
                                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                                        <div style={{width: "calc(50% - 3px)"}} className="contained-input-fld">
                                            <i className="fa-solid fa-envelope"></i>
                                            <input type="email" placeholder="Email"/>
                                        </div>
                                        <div style={{width: "calc(50% - 3px)"}} className="contained-input-fld">
                                            <i className="fa-solid fa-mobile"></i>
                                            <input type="number" placeholder="Mobile"/>
                                        </div>
                                    </div>
                                    <div style={{display: "flex", display: "none", flexDirection: "row", justifyContent: "space-between"}}>
                                        <div style={{width: "calc(50% - 3px)"}} className="contained-input-fld">
                                            <i className="fa-solid fa-ellipsis-vertical"></i>
                                            <select>
                                                <option>
                                                    Loan Type
                                                </option>
                                            </select>
                                        </div>
                                        <div style={{width: "calc(50% - 3px)"}} className="contained-input-fld">
                                            <i className="fa-solid fa-ellipsis-vertical"></i>
                                            <select>
                                                <option>
                                                    FICO Score
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div style={{ display: "none"}} className="contained-input-fld">
                                        <i className="fa-solid fa-dollar-sign"></i>
                                        <input type="number" placeholder="Loan Amount"/>
                                    </div>
                                    <div style={{cursor: "pointer", marginBottom: 30, marginTop: 10, textAlign: "center", backgroundColor: "red"/*#00e5cb"*/, color: "white", boxShadow: "0 0 10px rgba(0,0,0,0.4)", borderRadius: 50, padding: 14}}>
                                        Submit
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="wrapper">
                <div className="content">
                    <div style={{paddingTop: 10}}>
                        <p className="hp-page-section-title" style={{fontWeight: "bolder", fontSize: 27, textAlign: "center", color: "red"}}>
                            Up Next!
                        </p>
                        <p style={{textAlign: "center", maxWidth: 450, margin: "auto", marginBottom: 20, color: "white"}}>
                            We have a dedicated team of experts working around the clock to create our 
                            software & AI solutions/products. See some of our ongoing projects.
                        </p>
                        <div className="loan-programs-sections">
                            <div className="loan-programs-sections-item">
                                <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                    <div style={{marginRight: 10}}>
                                        <img style={{width: 40}} src={WelldugoLogo} alt="welldugo logo" />
                                    </div>
                                    <p className="loan-programs-sections-item-title" style={{fontWeight: "bolder", textAlign: "center"}}>
                                        Welldugo Travel With AI</p>
                                </div>
                                <div className="loan-programs-section-items-list">
                                    <p>This SaaS product is a smart tool for booking travel and hospitality products. 
                                        With AI assistant integrated, the experience in the booking process is improved for the user</p>
                                    <a target="_blank" style={{textDecoration: "none"}} 
                                        rel="noreferrer" href="https://willgo-test.herokuapp.com/">
                                        <p style={{color: "white", padding: 10, backgroundColor: "#175794", borderRadius: 50, cursor: "pointer", boxShadow: "1px 2px 3px rgba(0,0,0,0.33)"}}>
                                        click here to see the demo version</p>
                                    </a>
                                </div>
                            </div>
                            <div className="loan-programs-sections-item">
                                <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                    <div style={{marginRight: 10}}>
                                        <img style={{width: 40}} src={WelldugoLogo} alt="welldugo logo" />
                                    </div>
                                    <p className="loan-programs-sections-item-title" style={{fontWeight: "bolder", textAlign: "center"}}>
                                        Welldugo Travel With AI</p>
                                </div>
                                <div className="loan-programs-section-items-list">
                                    <p>This SaaS product is a smart tool for booking travel and hospitality products. 
                                        With AI assistant integrated, the experience in the booking process is improved for the user</p>
                                    <a target="_blank" style={{textDecoration: "none"}} 
                                        rel="noreferrer" href="https://willgo-test.herokuapp.com/">
                                        <p style={{color: "white", padding: 10, backgroundColor: "#175794", borderRadius: 50, cursor: "pointer", boxShadow: "1px 2px 3px rgba(0,0,0,0.33)"}}>
                                        click here to see the demo version</p>
                                    </a>
                                </div>
                            </div>
                            <div className="loan-programs-sections-item last">
                                <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                    <div style={{marginRight: 10}}>
                                        <img style={{width: 40}} src={WelldugoLogo} alt="welldugo logo" />
                                    </div>
                                    <p className="loan-programs-sections-item-title" style={{fontWeight: "bolder", textAlign: "center"}}>
                                        Welldugo Travel With AI</p>
                                </div>
                                <div className="loan-programs-section-items-list">
                                    <p>This SaaS product is a smart tool for booking travel and hospitality products. 
                                        With AI assistant integrated, the experience in the booking process is improved for the user</p>
                                    <a target="_blank" style={{textDecoration: "none"}} 
                                        rel="noreferrer" href="https://willgo-test.herokuapp.com/">
                                        <p style={{color: "white", padding: 10, backgroundColor: "#175794", borderRadius: 50, cursor: "pointer", boxShadow: "1px 2px 3px rgba(0,0,0,0.33)"}}>
                                        click here to see the demo version</p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}