export default function Hero(){
    return(
        <section className="hero">
            <div className="wrapper">
                <div className="hero-content">
                    <div>
                        <p className="hero-main-text">
                        <span style={{color: "red"}}>APP</span>LICATION SOFTWARE & <span style={{color: "red"}}>
                            AI</span> LAB.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}